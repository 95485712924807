@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 227, 4, 1);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 lightgreen;
    box-shadow: 0 0 0 0 lightgreen;
  }
  50% {
      -moz-box-shadow: 0 0 0 8px rgba(144,238,144, 0);
      box-shadow: 0 0 0 8px rgba(144,238,144, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

@keyframes spacerPulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 227, 4, 1);
    box-shadow: 0 0 0 0 rgba(0, 227, 4, 1);
  }
  70% {
      -moz-box-shadow: 0 0 0 8px rgba(204,169,44, 0);
      box-shadow: 0 0 0 8px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

@-webkit-keyframes pulse2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(4, 199, 199, 1);
  }
  50% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse2 {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(4, 199, 199, 1);
    box-shadow: 0 0 0 0 rgba(4, 199, 199, 1);
  }
  50% {
      -moz-box-shadow: 0 0 0 8px rgba(204,169,44, 0);
      box-shadow: 0 0 0 8px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes wiggle {
  0% { transform: rotate(0deg); }
 80% { transform: rotate(0deg); }
 85% { transform: rotate(5deg); }
 95% { transform: rotate(-5deg); }
100% { transform: rotate(0deg); }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(90%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}



*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

label {
  font-size: 1rem;
  color: white;
}

html,
body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #121212;
  width: 100%;
  overflow-x: hidden;
}

body {
  background: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.form-page{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-container {
  background-color: #182c25;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  border-radius: 0.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  max-width: 400px;
  height: auto;
  margin-top: 8rem;
}

.form-container > h2 {
  margin-block: 1rem;
  padding-bottom: .5rem;
  color: white;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
}

.form-container > form {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.form-container div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.form-container input {
  border: none;
  padding: 0.5rem;
  border-bottom: 1px solid gray;
  font-size: 1.1rem;
  outline: none;
  border-radius: 10px;
}

.form-container input::placeholder {
  font-size: 0.9rem;
  font-style: italic;
}

.form-container button {
  background-color: lightgreen;
  color: #182c25;
  border: none;
  padding: 0.6rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.3rem;
  font-family: 'Dosis', sans-serif;
}
span{
  text-align: center;
  color: white;
  font-size: .75rem;
}
span a {
  text-decoration: none;
  color: lightgreen;
  font-weight: bold;
  font-size: 1rem;
}

.signup-error{
  color: red;
  font-family: 'Dosis', sans-serif;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;

}

.home-button{
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-page{
  background: #182c25;
  color: white;
  display: flex;
  flex-direction: column;
}


.home-page-header{
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: .5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  width: 100vw;
  font-family: 'Bebas Neue', sans-serif;
  align-items: center;  
}

/* Navbar styles*/

.navbar img{
  position: fixed;
  right: .5rem;
  top: .5rem;
  z-index: 1000;
}
.nav-options{
  position: fixed;
  right: 0;
  top: 0;
  width: 50vw;
  background-color: #306844;
  color: white;
  border-bottom-left-radius: 10px;
  padding-bottom: 1rem;
  border-left: 2px solid lightgreen;
  border-bottom: 2px solid lightgreen;
  z-index: 900;
  animation: slideInFromRight ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  /*box-shadow: 3px 2px 3px 1px #5abe7d;*/
}

.nav-options ul{
  list-style: none;
  text-align: center;
  margin-top: 2.5rem;
}

.nav-options li{
  padding: 1rem;
  font-family: 'Inter', sans-serif;
}
.nav-options b{
  font-size: 1rem;
}
.nav-list-spacer{
  height: 2px;
  width: 8rem;
  background-color: rgba(58, 59, 58, .2);
  margin-left: auto;
  margin-right: auto;
}
.spacer{
  width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: .5rem;
}
.spacer-left{
  height: 4px;
  background-color: rgba(0, 227, 4, .75);
  width: 15%;
  animation: spacerPulse 2s infinite;
  border-radius: 1px;
}
.spacer-left-2{
  height: 4px;
  background-color: rgba(0, 227, 4, .75);
  width: 45%;
  animation: spacerPulse 2s infinite;
  border-radius: 1px;
}
.spacer-left-3{
  height: 4px;
  background-color: rgba(0, 227, 4, .75);
  width: 85%;
  animation: spacerPulse 2s infinite;
  border-radius: 1px;
}
.spacer-right{
  height: 4px;
  background-color: #3a3b3a;
  width: 85%;
}
.spacer-right-2{
  height: 4px;
  background-color: #3a3b3a;
  width: 55%;
}
.spacer-right-3{
  height: 4px;
  background-color: #3a3b3a;
  width: 15%;
}
.full-bar{
  height: 4px;
  background-color: rgba(0, 227, 4, .75);
  width: 50vw;
  animation: spacerPulse 2s infinite;
  border-radius: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.home-page-header h5{
  font-family: 'Dosis', sans-serif;
}
.div-title{
  font-family: 'Dosis', sans-serif;
}
.home-page-header img{
  max-width: 10vw;
  max-height: 10vh;
  padding-bottom: .25rem;
}
.header-expand{
  height: 6vh;
  width: 90%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
  box-shadow: 0 4px 4px 0 rgba(66, 68, 90, 1);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.header-hide{
  display: none;
}
.loading-div{
  width: 100vw;
  height: 100vh;
  background-color: #182c25;
}

/* Landing Page Styles */

.landing-page{
  width: 100vw;
  background-color: #182c25;
  color: white;
  display: flex;
  flex-direction: column;
}
.landing-page-image{
  width: 100%;
}
.landing-page-image img{
  max-width: 100vw;
}
.landing-img img{
  max-width: 40vw;
}
.landing-buttons{
  width: 80vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
.landing-name{
  font-family: 'Bebas Neue', sans-serif;
}
.landing-title-div{
  font-family: 'Dosis', sans-serif;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}
.landing-image{
  display: flex;
  flex-direction: column;
  align-content: center;

  /*animation: bounceInRight ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;*/
}

.landing-image img{
  max-height: 18vh;
  margin-left: auto;
  margin-right: auto;
  
}
.landing-welcome{
  margin-top: 1rem;
  margin-left: .5rem;
  font-family: 'Bebas Neue', sans-serif;
  /*animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;*/
}
.landing-welcome h4{
  font-size: 2rem;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 400;
}
.landing-intro b{
  color: #306844;
  font-size: 1.25rem;
  animation: fadeInAnimation ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.landing-brand{
  text-align: center;
  /*animation: slideInFromLeft ease 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;*/
}
.landing-brand p{
  /*animation: slideInFromLeft ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;*/
  color:white;
  font-weight: 500;
}
.landing-brand h4{
  font-family: 'Bebas Neue', sans-serif;
  font-size: 4rem;
  color: lightgreen;
}
.landing-text{
  font-family: 'Inter', sans-serif;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  font-weight: 300;
  background-color: rgba(58, 59, 58, .5);
  padding: 1.5rem;
  border-bottom-left-radius: 35px;
  border-top-right-radius: 35px;
}
.landing-intro{
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: white;
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
  
}
.landing-intro p{
  font-family: 'Inter', sans-serif;
  width: 90vw;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  color: #000;
  line-height: 1.6;
  margin-top: auto;
  margin-bottom: auto;
  /*animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;*/
  
}
.home_page span {
  color: #00e304;
}
.page-header{
  height: 3rem;
  background-color: #121212;
  font-family: 'Bebas Neue', sans-serif;
  color: #00e304;
  font-size: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #2c4c3b;
}
.page-header-left{
  color:  lightgreen;
  margin-left: .5rem;
}
.page-header-right{
  color: white;
}

.levelup-mushrooms {
  margin-left: auto;
  margin-right: auto;
}
.levelup-mushrooms img{
 max-width: 50vw;
}

.product-info-div{
  font-family: 'Dosis', sans-serif;
  width: 100vw;
  margin-top: 2rem;
}
.product-info-title{
  margin-left: .5rem;
  font-size: 1.5rem;
  font-family: 'Dosis', sans-serif;
  padding-top: 1rem;
}

.product-info-header{
  margin-left: .5rem;
  font-size: 1.5rem;
  font-family: 'Dosis', sans-serif;
  padding-top: 1rem;
}
.join-buttons{
  display: none;
}
.dt-signup{
  width: 33vw;
  height: 3rem;
  background-color: #2c4c3b;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dt-signin{
  width: 33vw;
  height: 3rem;
  background-color: #2c4c3b;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.join-img{
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 20vh;
}
.join-img img{
  max-height: 18vh;
  margin-left: auto;
  margin-right: auto;
}
.join-title{
  margin-left: .5rem;
  font-size: 1.5rem;
  font-family: 'Dosis', sans-serif;
  padding-top: 1rem;
}
.join-div-img{
  font-family: 'Dosis', sans-serif;
  width: 100vw;
  margin-top: 2rem;
}
.join-header{
  margin-left: 1rem;
  height: auto;
  font-family: 'Dosis', sans-serif;
  font-size: 2rem;
}
.join-now{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #121212;
  padding-bottom: 2rem;
}
.product-info-title h4{
  text-align: center;
  height: auto;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 3rem;
  color: lightgreen;
}
.product-info-title p{
  text-align: center;
  font-family: 'Dosis', sans-serif;
  width: 75vw;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-size: 1rem;
}
.join-text{
  font-family: 'Inter', sans-serif;
  text-align: center;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  color: #000;
  background-color: white;
  line-height: 1.6;
}
.join-text b{
  color: #306844;
}
.join-text p {
  width: 80vw;
}
.join-lvl-up{
  text-align: center;
    height: auto;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 3rem;
    color: lightgreen;
}
.mobile-buttons{
  display: flex;
  width: 80vw;
  height: 10vh;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  overflow: hidden;
}
.mobile-button{
  margin-top: 1.5rem;
  background-color: #306844;
  width: 38vw;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  color: white;
  font-weight: 600;
  border-radius: 10px;
}
.mobile-button p{
  width: fit-content;
}
.product-info{
  font-family: 'Dosis', sans-serif;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 2rem;
}

.product-info b{
  color: #306844;
}

.product-info li{
  margin-bottom: .5rem;
  list-style: none;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  color: black;
  padding: 1rem;
  border-bottom-left-radius: 35px;
  border-top-right-radius: 35px;
  width: 90vw;
  text-align: center;
  line-height: 1.6;
}

.product-info-li-spacer{
  height: 2px;
  width: 30vw;
  background-color: #656262;
  margin-left: auto;
  margin-right: auto;
}
.button-div{
  width: 35vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(58, 59, 58, .5);
  padding: 0;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.button-text{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.75rem;
  font-family: 'Bebas Neue', sans-serif;
  color: white;
}
.button-img{
  background-color: #455b55;
  padding: .5rem;
  height: 100%;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #182c25;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.live-header{
  font-family: 'Dosis', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: .5rem;
  text-align: center;
}
.live-readings{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
  border-radius: 10px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.live-tent-readings{
  display: flex;
  flex-direction: row;
}
.live-water-readings{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.live-readings h3{
  font-family: 'Dosis', sans-serif;
  text-align: center;
}
.readings-time{
  font-size: .75rem;
  margin-bottom: 1.5rem;
  text-align: center;
}
.live-tent{
  display: flex;
  flex-direction: column;
  width: 33%;
}
.readings-div{
  margin-left: auto;
  margin-right: auto;
}
.tent-reading-img, .water-reading-img{
  background-color: black;
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: .75rem;
}
.water-reading-img img{
  max-width: 24px;
  max-height: 24px;
}
.readingsContainer{
  font-family: 'Bebas Neue', sans-serif;
  padding: 2rem;
  font-size: 1rem;
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-bottom: 1rem;
  background-color: #2c4c3b;
}
.readingsContainer h1{
  margin-top: 1rem;
  font-family: 'Dosis', sans-serif;
}
.past-data-title{
  text-align: center;
  font-family: 'Dosis', sans-serif;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.past-data-title img{
  margin-right: .5rem;
}
.reading-chart-timestamp p{
  text-align: center;
}
.readings-divider{
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
  margin: 1rem;
}
.tooltip-div{
  padding: 6px;
  background-color: white;
  color: black;
  border-radius: 10px;
}
.tent-temp-chart{
  width: 80vw;
  height: 20vh;
  margin-left: -3rem;
}
.dashboard-img img{
  max-height: 20vh;
  max-width: 30vw;
}
.dashboard-buttons{
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.chart-toggle{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-family: 'Bebas Neue', sans-serif;
  border-radius: 5px;
  color: #6c826c;
  background-color: rgba(58, 59, 58, .75);
}
.chart-toggle-left{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  padding: .5rem;
}
.chart-toggle-center{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  padding: .5rem;
}
.chart-toggle-right{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  padding: .5rem;
}
.active{
  color: white;
  background-color: #182c25;
  width: 50%;
  border: 2px solid lightgreen;
  border-radius: 5px;
}
.toggle-show{
  font-size: .75rem;
  text-align: center;
  margin-bottom: 1rem;
}
.toggle-button{
  width: 25vw;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #00e304;
  padding: 0;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 1rem;
}
.chart-toggle-header{
  font-family: 'Bebas Neue', sans-serif;
}
.desktop-buttons{
  display: none;
}
.tent-reading-div{
  width: 21vw;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3a3b3a;
  padding: 0;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: .5rem;
}
.tent-reading-div p, .water-reading-div p{
  font-size: 1rem;
}
.water-reading-div{
  width: 21vw;
  height: 5vh;
  margin-bottom: .5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3a3b3a;
  padding: 0;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
}
.toggle-button-off{
  width: 25vw;
  height: 5vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: darkslategray;
  padding: 0;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
}
.toggle-button-img{
  width: 3rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid white;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #000;
}
.tent-reading-img{
  width: 2.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #121212;
  box-shadow: 0 0 8px 5px #00e304;
  animation: pulse 2s infinite;
}
.water-reading-img{
  width: 2.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #000;
  box-shadow: 0 0 8px 5px #04c7c7;
  animation: pulse2 2s infinite;
}
.water-reading-img p{
  font-size: .75rem;
}
.toggle-button-text{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Dosis', sans-serif;
  color: white;
}
.tent-reading{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Dosis', sans-serif;
  color: white;
}
.water-reading{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Dosis', sans-serif;
  color: white;
}
.footer{
  text-align: center;
}

/* Tech Specs Styles */

.tech-spec-button{
  display: none;
}
.tech-specs-intro{
  background-color: #182c25;
  color: white;
  padding-top: 2rem;
}
.tech-specs-intro h4{
  font-family: 'Bebas Neue', sans-serif;
  font-size: 2rem;
}
.tech-specs-intro-text, .tech-specs-arduino-text, .tech-specs-rp-text,
.ts-db-text, .ts-sensor-list{
  line-height: 1.6;
  text-align: center;
  padding: 1.5rem;
  font-family: 'Inter', sans-serif;
}
.tech-specs-intro-text p{
  padding-bottom: 1rem;
}
.ts-intro-img{
  float: left;
  margin-left: 1rem;
}
.ts-intro-img img{
  max-width: 35vw;
}
.tech-specs-arduino{
  padding-top: 2rem;
  background-color: white;
  color: #182c25;
  font-weight: 500;
}
.tech-specs-arduino h4{
  font-family: 'Bebas Neue', sans-serif;
  font-size: 2rem;
  margin-left: 1rem;
}
.arduino-img img{
  max-width: 50vw;
}
.arduino-img{
  float: right;
}
.tech-specs-rp{
  background-color: black;
  color: white;
  padding-top: 2rem;
}
.tech-specs-rp-text p{
  padding-bottom: 1rem;
}
.tech-specs-rp h4{
  font-family: 'Bebas Neue', sans-serif;
  font-size: 2rem;
}
.rp-img img{
  max-width: 50vw;
}
.rp-img{
  float: left;
}
.ts-rp-title, .ts-sensors-title, .ts-intro-title{
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}
.tech-specs-db{
  background-color: white;
  padding-top: 2rem;
}
.tech-specs-db h4{
  margin-left: 1rem;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 2rem;
}
.ts-db-text p{
  margin-bottom: 1rem;
}
.ts-db-img{
  float: right;
  margin-right: 1rem;
}
.ts-db-img img{
  max-width: 55vw;
}
.tech-specs-sensors{
  background-color: #182c25;
  padding-top: 2rem;
}
.tech-specs-sensors h4{
  font-family: 'Bebas Neue', sans-serif;
  font-size: 2rem;
  color: white;
}
.ts-sensor-list li{
  color: white;
  list-style: none;
  padding-bottom: .5rem;
}
.ts-sensor-list b{
  text-decoration: underline;
}
.ts-sensors-img img{
  max-width: 50vw;
}
.ts-sensors-img{
  float: left;
}
@media (min-width: 1025px){
  body{
    background-color: black;
    background-image: url('./images/background.png');
    background-repeat: no-repeat;
  }
  .page-header{
    left: 0;
    position: fixed;
    border: 0;
    background-color: rgba(0,0,0,0);
  }
  .landing-page{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  .landing-title-div{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: fit-content;
    margin-bottom: 0;
  }
  .landing-welcome{
    font-size: 1.5rem;
    margin-left: 2rem;
    margin-top: 2rem;
  }
  
  .landing-image img{
    max-height: 40vh;
  }
  .landing-brand{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .landing-brand h4{
    font-size: 4rem;
  }
  .landing-brand p{
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
  }
  .spacer{
    margin-top: 2rem;
    max-width: 20vw;
  }
  .landing-intro{
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: rgb(219, 240, 219);
    padding-bottom: 4rem;
    padding-top: 4rem;
    margin-bottom: 0;
    font-family: 'Inter', sans-serif;
  }
  .landing-intro p{
    line-height: 2;
    font-size: 1rem;
    width: 100%;
    
  }
  
  .product-info-title p{
    font-family: 'Inter', sans-serif;
  }
  .landing-intro b{
    font-size: 1rem;
  }
  .info-div-header{
    background-color: #182c25;
    max-width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .info-div-header h4{
    text-align: center;
  }
  
  .product-info{
    display: block;
    padding-right: 4rem;
    padding-left: 4rem;
    margin-top: 0;
    background-color: rgb(219, 240, 219);
    width: 100%;
  }
  .tech-spec-button{
    display: block;
    width: 25vw;
    background-color: #121212;
    border-radius: 10px;
    color: white;
    border: 2px solid lightgreen;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .product-info-title{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: auto;
    padding: 0;
  }
  .product-info-title p{
    width: 80%;
  }
  .product-info-title h4{
    font-size: 4rem;
  }
  .product-info-container{
    width: 100%;
    overflow: hidden;
  }
  .product-info-header{
    font-size: 2rem;
    font-family: 'Bebas Neue', sans-serif;
    margin-left: 2rem;
    margin-top: 1rem;
  }
  .product-info-header h4{
    font-weight: 400;
    color: lightgreen;
  }
  .product-info-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    
  }
  .product-info-div img{
    max-width: 20vw;
  }
  .product-info ul{
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
  .product-info li{
    width: auto;
    padding-bottom: 1rem;
    line-height: 3;
    padding: 1rem;
  }
  .join-container{
    background-color: #182c25;
    display: flex;
    flex-direction: row;
    border: none;
    padding-bottom: 2rem;
    width: 100%;
    overflow: hidden;
  }
  .join-title-div{
    width: 50vw;
    background-color: #182c25;
    height: fit-content;
    display: flex;
    flex-direction: column;
    float: left;
  }
  .join-div-img{
    width: auto;
    margin-top: 0;
  }
  .join-img{
    height: fit-content;
  }
  .join-img img{
    max-width: 15vw;
    max-height: fit-content;
  }
  .join-header{
    font-family: 'Bebas Neue', sans-serif;
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .join-header h4{
    font-weight: 400;
  }
  .join-lvl-up{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 3rem;
    margin-left: auto;
    color: lightgreen;
    animation: wiggle 2s infinite;
  }
  .join-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-right: 3px solid #182c25;
    background-color: #182c25;
    padding: 1rem;

  }
  .join-text p{
    width: auto;
    line-height: 2;
    color: white;
    font-family: 'Inter', sans-serif; 
  }
  .join-text b{
    color: lightgreen;
  }
  .join-now{
    background-color: white;
    display: none;
  }
  .desktop-buttons{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .dt-signup{
    background-color: #121212;
    border-radius: 10px;
    color: white;
    border: 2px solid lightgreen;
    margin-right: 1rem;
  }
  .dt-signin{
    background-color: #121212;
    border-radius: 10px;
    color: white;
    border: 2px solid lightgreen;
  }
  .footer{
    background-color: #121212;
    width: 100%;
    display: none;
  }
  .nav-options{
    width: 25vw;
  }
  .tech-specs-divs{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .tech-specs-intro{
    height: auto;
    padding-bottom: 2rem;
  }
  .ts-intro-title{
    margin-right: 4rem;
    margin-top: 4rem;
  }
  .ts-intro-img img{
    max-height: 40vh;
  }
  .arduino-img img{
    max-height: 33vh;
  }
  .ts-db-img img{
    max-width: 40vw;
  }
  .tech-specs-arduino{
    height: 45vh;
  }
  .tech-specs-intro-text, .tech-specs-arduino-text, .tech-specs-rp-text, .ts-db-text, .ts-sensor-list{
    font-size: 1.25rem;
  }
  .ts-sensors-img img{
    max-height: 40vh;
  }
  .form-container{
    margin-left: auto;
    margin-right: auto;
    width: 40vw;
  }
  .readingsContainer{
    max-width: 30vw;
    margin: .5rem;
  }
  .tent-temp-chart{
    max-width: 25vw;
  }
  .chart-toggle{
    width: 50vw;
  }
  .chart-group{
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-wrap: wrap;
  }
  .mobile-buttons{
    display: none;
  }
  .navbar img{
    position: fixed;
    right: 1rem;
    top: 1rem;
    z-index: 1000;
    width: 2rem;
  }
  .spacer{
    display: none;
  }
}